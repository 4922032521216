<template>
  <div>
    <a-alert
      :message="$t('scolarite.listeModulesSpec')"
      :description="$t('scolarite.infoModuleSpec')"
      type="success"
      show-icon
      closable
    />
    <div class="row">
      <div class="col-md-2">
        <a-select
          size="large"
          :default-value="defaultLevel[schoolType]"
          @change="handleModuleChange"
          class="pb-2 pt-2"
          style="width: 100%"
        >
          <a-select-option
            v-for="(value, name, index) in levelSelectData[schoolType]"
            :key="index"
            :value="name"
          >
            {{ value }}</a-select-option
          >
        </a-select>
      </div>

      <div class="col-md-4 row">
        <div class="col-md-5 pt-2 center">
          <label style="margin-right: 5px; margin-left: 15px">{{
            this.$t("emploi.trimestre")
          }}</label>
        </div>
        <div class="col-md-7">
          <a-select
            size="large"
            :placeholder="$t('paiement.selectionnerMois')"
            style="width: 100%; height: 100%; margin-left: 15px"
            class="pb-2 p-2"
            @change="
              (val) => {
                this.selectedTrimester = val;
                this.handleModuleChange(this.levelSelected);
              }
            "
            default-value="1"
          >
            <a-select-option
              v-for="trimester in ['1', '2', '3']"
              :key="trimester"
              :value="trimester"
            >
              {{ trimester }}
            </a-select-option>
          </a-select>
        </div>
      </div>

      <div class="col-md-2">
        <a-button
          type="primary"
          class="mx-auto my-2"
          @click="cloneModel = true"
          style="width: 100%"
        >
          {{ $t("scolarite.duppModules") }}
        </a-button>
      </div>
      <div class="col-md-2">
        <a-button
          type="primary"
          icon="book"
          class="mx-auto my-2"
          @click="showAddModal"
          style="width: 100%"
        >
          {{ $t("action.ajouter") }}
        </a-button>
      </div>
      <div class="col-md-2">
        <a-popconfirm
          :title="$t('bulletin.defaultModuleConfirm')"
          @confirm="setDefaultModules"
        >
          <a-button
            :loading="defaultLoading"
            icon="undo"
            class="mx-auto my-2"
            style="width: 100%"
          >
            {{ $t("bulletin.defaultModule") }}
          </a-button>
        </a-popconfirm>
      </div>
    </div>

    <div class="card">
      <!-- add module form -->
      <a-modal
        v-model="visibleAddModal"
        @cancel="handleCancel('add')"
        :title="$t('scolarite.ajouterModule')"
        :footer="false"
        width="50%"
      >
        <a-form :form="addForm" @submit="addModule">
          <a-form-item :label="$t('scolarite.nom')">
            <a-input
              :placeholder="$t('scolarite.nom')"
              v-decorator="[
                'name',
                {
                  rules: [
                    {
                      required: true,
                      message: $t('requis.nom'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item :label="$t('scolarite.coefficient')">
            <a-input-number
              :placeholder="$t('scolarite.coefficient')"
              v-decorator="[
                'coef',
                {
                  rules: [
                    {
                      required: true,
                      message: $t('requis.coefficient'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item :label="$t('scolarite.listeMatieres')">
            <div class="card" style="width: 100%; padding: 10px">
              <a-row v-for="(item, index) in selectedSubjects" :key="index">
                <a-col :span="12">
                  <a-select
                    label-in-value
                    :placeholder="$t('emploi.choisirMatiere')"
                    :default-value="item.subject"
                    v-model="item.subject"
                    @change="
                      (val) => {
                        item.subject = val;
                      }
                    "
                  >
                    <a-select-option
                      v-for="subject in unusedSubjects"
                      :key="subject._id"
                      :value="subject._id"
                    >
                      {{ subject.name }}
                    </a-select-option>
                  </a-select>
                </a-col>
                <a-col :span="10">
                  <a-input-number
                    style="width: 100%"
                    class="ml-2"
                    :value="item.coef"
                    v-model="item.coef"
                    :placeholder="$t('scolarite.coefficient')"
                /></a-col>
                <a-col :span="2">
                  <a-button
                    type="dashed"
                    class="ml-4"
                    shape="circle"
                    icon="delete"
                    @click="
                      () => {
                        selectedSubjects.splice(index, 1);
                      }
                    "
                  />
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="10"></a-col>
                <a-col :span="8">
                  <a-button
                    type="dashed"
                    class="mr-3 m-auto"
                    @click="
                      () => {
                        selectedSubjects.push({
                          subject: undefined,
                          coef: undefined,
                        });
                      }
                    "
                  >
                    {{ $t("scolarite.ajouterMatiere") }}
                  </a-button>
                </a-col>
              </a-row>
            </div>
          </a-form-item>
          <div class="form-actions mt-0">
            <a-button
              type="primary"
              htmlType="submit"
              :loading="loadingClasse"
              class="mr-3"
            >
              {{ $t("action.ajouter") }}
            </a-button>
            <a-button
              key="back"
              @click="handleCancel('add')"
              :disabled="loadingClasse"
            >
              {{ $t("action.annuler") }}
            </a-button>
          </div>
        </a-form>
      </a-modal>
      <!-- edit module modal-->
      <a-modal
        v-model="visibleModal"
        @cancel="handleCancel"
        :title="
          $t('scolarite.modifierListeMatieres', { name: selectedModule.name })
        "
        :footer="false"
        width="50%"
        v-if="selectedModule"
      >
        <a-form :form="form" @submit="editModule">
          <a-form-item :label="$t('scolarite.nom')">
            <a-input
              :placeholder="$t('scolarite.nom')"
              v-decorator="[
                'name',
                {
                  initialValue: selectedModule.name,
                  rules: [
                    {
                      required: true,
                      message: $t('requis.nom'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item :label="$t('scolarite.coefficient')">
            <a-input-number
              :placeholder="$t('scolarite.coefficient')"
              :value="selectedModule.coef"
              v-decorator="[
                'coef',
                {
                  rules: [
                    {
                      required: true,
                      message: $t('requis.coefficient'),
                    },
                  ],
                  initialValue: selectedModule.coef,
                },
              ]"
            />
          </a-form-item>
          <a-form-item :label="$t('scolarite.listeMatieres')">
            <!-- <a-transfer
              :titles="[$t('scolarite.nonAffectes'), $t('scolarite.affectes')]"
              :data-source="listSubjects"
              show-search
              :list-style="{
                width: '290px',
                height: '290px',
              }"
              :target-keys="targetKeys"
              @change="handleChangeTransfer"
              :render="renderItem"
            /> -->

            <div class="card" style="width: 100%; padding: 10px">
              <a-row v-for="(item, index) in selectedSubjects" :key="index">
                <a-col :span="12">
                  <a-select
                    label-in-value
                    :placeholder="$t('emploi.choisirMatiere')"
                    :default-value="item.subject"
                    v-model="item.subject"
                    @change="
                      (val) => {
                        item.subject = val;
                      }
                    "
                  >
                    <a-select-option
                      v-for="subject in unusedSubjects"
                      :key="subject._id"
                      :value="subject._id"
                    >
                      {{ subject.name }}
                    </a-select-option>
                  </a-select>
                </a-col>
                <a-col :span="10">
                  <a-input-number
                    class="ml-2"
                    style="width: 100%"
                    :value="item.coef"
                    v-model="item.coef"
                    :placeholder="$t('scolarite.coefficient')"
                /></a-col>
                <a-col :span="2">
                  <a-button
                    type="dashed"
                    class="ml-4"
                    shape="circle"
                    icon="delete"
                    @click="
                      () => {
                        selectedSubjects.splice(index, 1);
                      }
                    "
                  />
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="10"></a-col>
                <a-col :span="8">
                  <a-button
                    type="dashed"
                    class="mr-3 m-auto"
                    @click="
                      () => {
                        selectedSubjects.push({
                          subject: undefined,
                          coef: undefined,
                        });
                      }
                    "
                  >
                    {{ $t("scolarite.ajouterMatiere") }}
                  </a-button>
                </a-col>
              </a-row>
            </div>
          </a-form-item>
          <div class="form-actions mt-0">
            <a-button
              type="primary"
              htmlType="submit"
              :loading="loadingClasse"
              :disabled="loadingClasse"
              class="mr-3"
            >
              {{ $t("action.modifier") }}
            </a-button>
            <a-button
              key="back"
              @click="handleCancel('edit')"
              :disabled="loadingClasse"
            >
              {{ $t("action.annuler") }}
            </a-button>
          </div>
        </a-form>
      </a-modal>

      <a-table
        :loading="tableLeading"
        :rowKey="'_id'"
        :columns="columns"
        :data-source="data"
        :pagination="true"
        :scroll="{ x: 'max-content' }"
        :customRow="
          (record) => {
            return {
              on: {
                click: () => {
                  affSubjects(record);
                },
              },
            };
          }
        "
      >
        <template slot="subjects" slot-scope="text, record">
          <div
            v-for="subject in record.subjects"
            :key="subject._id"
            class="arabicStyle"
          >
            -
            <a-tag color="green" class="arabicStyle"
              >{{ subject.name }} ({{
                record.subjectsCoef ? record.subjectsCoef[subject._id] : 1
              }})</a-tag
            >
          </div>
        </template>
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`${$t('personnel.chercher')} ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
            >{{ $t("paiement.chercher") }}</a-button
          >
          <a-button
            size="small"
            style="width: 90px"
            @click="() => handleReset(clearFilters)"
            >{{ $t("action.reinitialiser") }}</a-button
          >
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        />
        <span slot="operationSupprimer" slot-scope="text, record">
          <div class="editable-row-operations">
            <span>
              <a-button :disabled="editingKey !== ''" type="danger" @click.stop>
                <a-popconfirm
                  :title="$t('requis.supp')"
                  @confirm="() => supp(record._id)"
                >
                  <a>{{ $t("action.supprimer") }}</a>
                </a-popconfirm>
              </a-button>
            </span>
          </div>
        </span>
      </a-table>
    </div>

    <div class="card">
      <!-- add module form -->
      <a-modal
        v-model="cloneModel"
        @cancel="cloneModel = false"
        :title="$t('scolarite.ajouterModule')"
        :footer="false"
        width="50%"
      >
        <div>
          <div class="">
            <label>{{ this.$t("actualite.trimester_one") }}</label>
          </div>
          <div class="">
            <a-select
              size="large"
              :placeholder="$t('paiement.selectionnerMois')"
              style="width: 100%; height: 100%"
              class="pb-2 p-2"
              @change="
                (val) => {
                  this.trimester_one = val;
                }
              "
              default-value="1"
            >
              <a-select-option
                v-for="trimester in ['1', '2', '3']"
                :key="trimester"
                :value="trimester"
              >
                {{ trimester }}
              </a-select-option>
            </a-select>
          </div>
        </div>

        <div>
          <div class="">
            <label>{{ this.$t("actualite.trimester_two") }}</label>
          </div>
          <div class="">
            <a-select
              size="large"
              :placeholder="$t('paiement.selectionnerMois')"
              style="width: 100%; height: 100%"
              class="pb-2 p-2"
              @change="
                (val) => {
                  this.trimester_two = val;
                }
              "
              default-value="2"
            >
              <a-select-option
                v-for="trimester in ['1', '2', '3']"
                :key="trimester"
                :value="trimester"
              >
                {{ trimester }}
              </a-select-option>
            </a-select>
          </div>
        </div>

        <div class="form-actions mt-0">
          <a-button
            type="primary"
            :loading="loadingClasse"
            class="mr-3"
            @click="cloneModelByTrimester()"
          >
            {{ $t("action.clone") }}
          </a-button>
          <a-button
            key="back"
            @click="cloneModel = false"
            :disabled="loadingClasse"
          >
            {{ $t("action.annuler") }}
          </a-button>
        </div>
      </a-modal>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import { Modal } from "ant-design-vue";
import { mapState } from "vuex";

var modulesData = [];
export default {
  name: "classrooms",
  computed: {
    ...mapState(["settings", "user"]),
    unusedSubjects() {
      return this.listSubjects.filter(
        (s) =>
          !this.selectedSubjects.find(
            (ss) => ss.subject && ss.subject.key == s._id
          )
      );
    },
  },
  beforeMount() {
    this.form = this.$form.createForm(this, { name: "SubjectForm" });
  },
  async created() {
    this.$gtag.pageview({
      page_path: this.$route.path,
      page_location: window.location.href,
      page_title: document.title + " - Les modules pilote",
    });

    this.schoolType = this.user.building.find(
      (el) => el.dbName == this.settings.activeBuilding
    ).type;
    this.levelSelected = Number(this.defaultLevel[this.schoolType]);
    this.getModules(this.defaultLevel[this.schoolType]);

    //Get teachers
    await apiClient
      .post("/subject/filter", {
        query: {
          status: "active",
        },
      })
      .then((res) => {
        this.listSubjects = res.data.map((elem) => ({
          ...elem,
          key: elem._id,
        }));
      })
      .catch(() => this.$message.error(this.$t("error.aucMatiere")));
  },
  data() {
    return {
      cloneModel: false,
      trimester_one: "1",
      trimester_two: "2",
      defaultLoading: false,
      schoolType: null,
      selectedTrimester: "1",
      columns: [
        {
          title: this.$t("scolarite.nomModule"),
          dataIndex: "name",
          key: "name",
          sorter: (a, b) => a.name.length - b.name.length,
          sortDirections: ["descend", "ascend"],
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "name",
          },
          onFilter: (value, record) =>
            record.name.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("scolarite.coefficient"),
          dataIndex: "coef",
          sorter: true,
          key: "coef",
        },
        {
          title: this.$t("scolarite.listeMatieres"),
          dataIndex: "subjects",
          key: "subjects",
          scopedSlots: {
            customRender: "subjects",
          },
        },
        {
          title: this.$t("action.supprimer"),
          dataIndex: "operationSupprimer",
          scopedSlots: { customRender: "operationSupprimer" },
          fixed: "right",
        },
      ],
      tableLeading: true,
      visibleModal: false,
      visibleAddModal: false,
      form: this.$form.createForm(this),
      addForm: this.$form.createForm(this),
      data: null,
      cacheData: null,
      loadingClasse: false,
      editingKey: "",
      selectedModule: null,
      listSubjects: [],
      selectedSubjects: [],
      targetKeys: [],
      levelSelected: 1,
      targetAddKeys: [],
      defaultLevel: {
        ecole: "1",
        college: "7",
        lycee: "10",
        collegeEtLycee: "7",
        primaireEtCollege: "1",
      },
      levelSelectData: {
        jardinEnfant: {
          "-2": this.$t("niveau.niveauPre"),
          "-1": this.$t("niveau.niveau0"),
        },
        ecole: {
          "-2": this.$t("niveau.niveauPre"),
          "-1": this.$t("niveau.niveau0"),
          1: this.$t("niveau.niveau1"),
          2: this.$t("niveau.niveau2"),
          3: this.$t("niveau.niveau3"),
          4: this.$t("niveau.niveau4"),
          5: this.$t("niveau.niveau5"),
          6: this.$t("niveau.niveau6"),
        },
        college: {
          7: this.$t("niveau.niveau7"),
          8: this.$t("niveau.niveau8"),
          9: this.$t("niveau.niveau9"),
        },
        lycee: {
          10: this.$t("niveau.niveau10"),
          11: this.$t("niveau.niveau11"),
          12: this.$t("niveau.niveau12"),
          13: this.$t("niveau.niveau13"),
        },
        collegeEtLycee: {
          7: this.$t("niveau.niveau7"),
          8: this.$t("niveau.niveau8"),
          9: this.$t("niveau.niveau9"),
          10: this.$t("niveau.niveau10"),
          11: this.$t("niveau.niveau11"),
          12: this.$t("niveau.niveau12"),
          13: this.$t("niveau.niveau13"),
        },
        primaireEtCollege: {
          "-2": this.$t("niveau.niveauPre"),
          "-1": this.$t("niveau.niveau0"),
          1: this.$t("niveau.niveau1"),
          2: this.$t("niveau.niveau2"),
          3: this.$t("niveau.niveau3"),
          4: this.$t("niveau.niveau4"),
          5: this.$t("niveau.niveau5"),
          6: this.$t("niveau.niveau6"),
          7: this.$t("niveau.niveau7"),
          8: this.$t("niveau.niveau8"),
          9: this.$t("niveau.niveau9"),
        },
      },
    };
  },
  methods: {
    async cloneModelByTrimester() {
      this.loadingClasse = true;
      await apiClient
        .put(
          "/moduleSpecific/clone/" +
            this.trimester_one +
            "/" +
            this.trimester_two +
            "/" +
            this.levelSelected +
            ""
        )
        .then(({ data }) => {
          this.data = data;
          this.cacheData = data;
          this.cloneModel = false;
        })
        .catch((err) => {
          this.$message.error(this.$t("error.erreur"));
          console.log(err);
        })
        .finally(() => {
          this.loadingClasse = false;
        });
    },

    setDefaultModules() {
      this.defaultLoading = true;
      apiClient
        .post("/moduleSpecific/reset", {
          trimester: this.selectedTrimester,
        })
        .then((res) => {
          this.getModules(this.levelSelected)
            .then(() => {
              this.$message.success(this.$t("bulletin.defaultDone"));
            })
            .catch((e) =>
              this.$message.error(this.$t("error.impoModifiMatieres"))
            );
        })
        .catch((e) => this.$message.error(this.$t("error.impoModifiMatieres")))
        .finally(() => {
          this.defaultLoading = false;
        });
    },
    async getModules(level, trimester) {
      await apiClient
        .post("/moduleSpecific/filter", {
          query: {
            status: "active",
            level: level,
            trimester: trimester ? trimester : 1,
          },
        })
        .then((res) => {
          modulesData = [...res.data];
          if (modulesData.length == 0)
            this.$message.warning(this.$t("error.aucModule"));
          modulesData = modulesData.map((elem) => {
            return {
              ...elem,
              key: elem._id,
            };
          });
          this.data = modulesData;
          this.cacheData = modulesData.map((item) => ({ ...item }));
          this.tableLeading = false;
        })
        .catch((e) => {
          this.$message.error(this.$t("error.erreur"));
          this.tableLeading = false;
        });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    editModule(e) {
      e.preventDefault();

      const thereEmpty = this.selectedSubjects.filter(
        (s) => !s.subject || !s.coef
      );

      if (
        !this.selectedSubjects ||
        this.selectedSubjects.length == 0 ||
        thereEmpty.length > 0
      ) {
        this.$message.warning(this.$t("error.matiereNonAffecte"));
        return;
      }

      this.loadingClasse = true;

      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
        }
        values.subjects = this.selectedSubjects.map((s) => s.subject.key);
        values.subjectsCoef = {};
        this.selectedSubjects.map((s) => {
          values.subjectsCoef[s.subject.key] = s.coef;
        });

        apiClient
          .patch("/moduleSpecific/" + this.selectedModule._id, {
            data: values,
          })
          .then((res) => {
            this.selectedModule.subjects = this.listSubjects.filter((item) =>
              this.selectedSubjects.some((d) => d.subject.key === item._id)
            );
            this.selectedModule.coef = values.coef;
            this.selectedModule.name = values.name;
            this.selectedModule.subjectsCoef = values.subjectsCoef;

            var foundIndex = this.data.findIndex(
              (x) => x._id == this.selectedModule._id
            );
            this.data[foundIndex] = this.selectedModule;
            this.$message.success(
              this.$t("success.modificationsMatieresEffectue")
            );

            this.handleCancel();
          })
          .catch((e) =>
            this.$message.error(this.$t("error.impoModifiMatieres"))
          )
          .finally(() => (this.loadingClasse = false));
      });
    },
    addModule(e) {
      e.preventDefault();

      const thereEmpty = this.selectedSubjects.filter(
        (s) => !s.subject || !s.coef
      );

      if (
        !this.selectedSubjects ||
        this.selectedSubjects.length == 0 ||
        thereEmpty.length > 0
      ) {
        this.$message.warning(this.$t("error.matiereNonAffecte"));
        return;
      }

      this.addForm.validateFields((err, values) => {
        if (!err) {
          this.loadingClasse = true;

          console.log("Received values of form: ", values);

          values.subjects = this.selectedSubjects.map((s) => s.subject.key);
          values.subjectsCoef = {};
          this.selectedSubjects.map((s) => {
            values.subjectsCoef[s.subject.key] = s.coef;
          });
          values.level = this.levelSelected;
          values.trimester = this.selectedTrimester;

          console.log(values);
          apiClient
            .put("/moduleSpecific/", {
              data: values,
            })
            .then((res) => {
              res.data.key = res.data._id;
              this.data.push(res.data);
              this.$message.success(
                this.$t("success.modificationsMatieresEffectue")
              );

              this.handleCancel();
            })
            .catch((e) =>
              this.$message.error(this.$t("error.impoModifiMatieres"))
            )
            .finally(() => {
              this.loadingClasse = false;
              this.targetAddKeys = [];
              this.visibleAddModal = false;
            });
        }
      });
    },
    affSubjects(record) {
      this.selectedModule = record;
      this.targetKeys = record.subjects.map((elem) => elem._id);

      this.selectedSubjects = [];
      record.subjects.map((elem) => {
        this.selectedSubjects.push({
          subject: { key: elem._id, label: elem.name },
          coef: record.subjectsCoef ? record.subjectsCoef[elem._id] : 1,
        });
      });
      this.showModal();
    },
    renderItem(item) {
      const customLabel = <span class="custom-item">{item.name}</span>;

      return {
        label: customLabel, // for displayed item
        value: item.name, // for title and filter matching
      };
    },
    handleChangeTransfer(targetKeys, direction, moveKeys) {
      this.targetKeys = targetKeys;
    },
    handleAddChangeTransfer(targetKeys, direction, moveKeys) {
      this.targetAddKeys = targetKeys;
    },
    showModal() {
      this.visibleModal = true;
    },
    showAddModal() {
      this.visibleAddModal = true;
      this.selectedSubjects = [];
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleCancel(type = "edit") {
      console.log(type);
      if (type == "edit") {
        this.visibleModal = false;
        this.form.resetFields();
      } else {
        this.addForm.resetFields();
        this.visibleAddModal = false;
        this.targetAddKeys = [];
      }
    },
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },

    handleModuleChange(value) {
      this.levelSelected = value;
      this.getModules(value, this.selectedTrimester);
      console.log(
        `selected ${this.levelSelected}, Trimester: ${this.selectedTrimester}`
      );
    },

    confirmDelete(key) {
      apiClient
        .delete("/moduleSpecific/" + key)
        .then(() => {
          this.$message.success(this.$t("success.moduleSupp"));
          const dataSource = [...this.data];
          this.data = dataSource.filter((item) => item.key !== key);
        })
        .catch(() => this.$message.error(this.$t("error.moduleNonSupp")))
        .finally(() => (this.visibleModal = false));
    },

    supp(key) {
      const classroomsUsed = [];

      apiClient
        .post("/schedules/filter", {
          query: {
            type: "MS",
            moduleSpecific: key,
            status: { $ne: "inactive" },
          },
          aggregation: [
            {
              $lookup: {
                from: "classrooms",
                localField: "classeRoom",
                foreignField: "_id",
                as: "classeRoom",
              },
            },
            {
              $project: {
                _id: 1,
                classeRoom: {
                  $let: {
                    vars: {
                      classVar: {
                        $arrayElemAt: ["$classeRoom", 0],
                      },
                    },
                    in: {
                      name: "$$classVar.name",
                      _id: "$$classVar._id",
                    },
                  },
                },
              },
            },
          ],
        })
        .then(({ data }) => {
          data.map((sched) => {
            if (sched.classeRoom && sched.classeRoom.name)
              classroomsUsed.push(sched.classeRoom.name);
          });
          if (classroomsUsed.length > 0)
            Modal.confirm({
              title: this.$t("scolarite.confirmDeleteModule"),
              content: (h) => {
                let list = [];
                classroomsUsed.map((s) => {
                  list.push(h("li", s));
                });
                return h("ul", list);
              },
              okText: this.$t("action.supprimer"),
              okType: "danger",
              cancelText: this.$t("action.annuler"),
              onOk: () => {
                this.confirmDelete(key);
              },
              onCancel() {},
            });
          else {
            this.confirmDelete(key);
          }
        })
        .catch((e) => {
          this.$message.error(this.$t("error.moduleNonSupp"));
          console.log(e);
        })
        .finally(() => (this.visibleModal = false));
    },
  },
};
</script>
<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.arabicStyle {
  direction: rtl;
  font-size: 20px;
}
.card >>> .ant-table-tbody tr {
  cursor: pointer !important;
}

.ant-modal-body .card {
  border-color: black !important;
}

.center {
  display: flex;
  align-items: center;
}
</style>
