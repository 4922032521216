<template>
  <div>
    <div style="text-align: center">
      <span>{{ $t("appreciation.withOptions") }}</span>
      <a-switch
        class="m-3"
        :loading="tableLeading"
        v-model="manualAppreciation"
        @change="changeAppreciationMethod"
      />
      <span>{{ $t("appreciation.manual") }}</span>
    </div>

    <div v-if="!manualAppreciation">
      <a-tag
        :color="data.length == 15 ? 'red' : 'green'"
        style="position: absolute; right: 40px; font-size: 15px"
      >
        {{ data.length }} / 15 {{ $t("appreciation.appreciation") }}
      </a-tag>
    </div>

    <a-button
      v-if="!manualAppreciation"
      icon="book"
      :disabled="data.length >= 15"
      @click="showModal"
      style="margin-bottom: 20px"
    >
      {{ $t("appreciation.add") }}
    </a-button>

    <a-modal
      v-model="visibleModal"
      @cancel="handleCancel"
      :title="$t('all.enterInformationBelow')"
      :footer="false"
    >
      <a-form :form="form" @submit="addAppreciationOption">
        <a-form-item :label="$t('appreciation.appreciation')">
          <a-textarea
            :maxLength="40"
            v-decorator="[
              'appreciation',
              {
                rules: [
                  {
                    required: true,
                    message: $t('requis.appreciation'),
                  },
                ],
              },
            ]"
            :placeholder="$t('appreciation.appreciation')"
            :auto-size="{ minRows: 2, maxRows: 5 }"
          />
        </a-form-item>
        <div class="form-actions mt-0">
          <a-button
            type="primary"
            htmlType="submit"
            :loading="loadingClasse"
            :disabled="loadingClasse"
            class="mr-3"
          >
            {{ $t("action.ajouter") }}
          </a-button>
          <a-button
            key="back"
            @click="handleCancel"
            :disabled="loadingClasse"
            >{{ $t("action.annuler") }}</a-button
          >
        </div>
      </a-form>
    </a-modal>
    <div class="card" v-if="!manualAppreciation">
      <a-table
        :loading="tableLeading"
        :rowKey="'_id'"
        :columns="columns"
        :data-source="data"
        :pagination="5"
      >
        <template slot="appreciation" slot-scope="text, record">
          <div key="appreciation">
            <a-textarea
              v-if="record.editable"
              style="margin: -5px 0"
              :value="text"
              @change="
                (e) => handleChange(e.target.value, record.id, 'appreciation')
              "
            />
            <template v-else> {{ text }} </template>
          </div>
        </template>
        <span slot="operation" slot-scope="text, record">
          <div class="editable-row-operations">
            <span v-if="record.editable">
              <a-button
                size="small"
                style="margin-left: 5px; margin-right: 5px"
                type="primary"
                @click="() => save(record.id)"
              >
                {{ $t("action.enregistrer") }}
              </a-button>
              <a-button size="small" type="danger">
                <a-popconfirm
                  :title="$t('all.sureToCancelAlert')"
                  @confirm="() => cancel(record.id)"
                >
                  <a>{{ $t("action.annuler") }}</a>
                </a-popconfirm>
              </a-button>
            </span>
            <span v-else>
              <a-button
                type="primary"
                :disabled="editingKey !== ''"
                @click="() => edit(record.id)"
              >
                <a-icon type="edit" />{{ $t("action.modifier") }}
              </a-button>
            </span>
          </div>
        </span>
        <span slot="operationSupprimer" slot-scope="text, record">
          <div class="editable-row-operations">
            <span>
              <a-button :disabled="editingKey !== ''" type="danger">
                <a-popconfirm
                  :title="$t('requis.supp')"
                  @confirm="() => supp(record.id)"
                >
                  <a>{{ $t("action.supprimer") }}</a>
                </a-popconfirm>
              </a-button>
            </span>
          </div>
        </span>
      </a-table>
    </div>
    <a-alert
      v-else
      :message="$t('appreciation.appreciation')"
      :description="$t('appreciation.teacherManualDesc')"
      type="info"
    />
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import { mapState } from "vuex";

export default {
  name: "AppreciationOptions",
  beforeMount() {
    this.form = this.$form.createForm(this, {
      name: "AppreciationOptionsForm",
    });
  },
  async created() {
    this.$gtag.pageview({
      page_path: this.$route.path,
      page_location: window.location.href,
      page_title: document.title + " - Appréciation",
    });

    this.tableLeading = true;
    await apiClient
      .post("/buildings/filter", {
        query: {
          _id: this.settings.activeBuildingId,
        },
      })
      .then((res) => {
        const data = res.data[0];

        this.manualAppreciation = data.manualAppreciation;

        let AppreciationOptionsData = [...data.appreciationOptions];
        AppreciationOptionsData = AppreciationOptionsData.map((elem) => {
          return {
            ...elem,
            key: elem.id,
          };
        });
        this.data = AppreciationOptionsData;
        this.cacheData = AppreciationOptionsData.map((item) => ({ ...item }));
        this.tableLeading = false;
      })
      .catch((e) => {
        console.log(e);
        this.$message.error(this.$t("error.erreur"));
      });
  },
  data() {
    return {
      manualAppreciation: false,
      columns: [
        {
          title: this.$t("appreciation.appreciation"),
          dataIndex: "appreciation",
          key: "appreciation",
          scopedSlots: { customRender: "appreciation" },
        },
        {
          title: this.$t("action.modifier"),
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          fixed: "right",
        },
        {
          title: this.$t("action.supprimer"),
          dataIndex: "operationSupprimer",
          scopedSlots: { customRender: "operationSupprimer" },
          fixed: "right",
        },
      ],
      tableLeading: true,
      visibleModal: false,
      form: this.$form.createForm(this),
      data: null,
      cacheData: null,
      loadingClasse: false,
      editingKey: "",
    };
  },
  computed: mapState(["settings", "user"]),

  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    addAppreciationOption(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.loadingClasse = true;

          apiClient
            .put("/appreciationOption", { data: values })
            .then((res) => {
              this.$message.success(this.$t("success.appreciationOptionAjout"));
              res.data.key = res.data.id;
              this.data.push(res.data);
              this.cacheData = this.data.map((item) => ({ ...item }));
              this.handleCancel();
            })
            .catch((e) =>
              this.$message.error(this.$t("error.impoAjoutAppreciationOption"))
            )
            .finally(() => (this.loadingClasse = false));
        }
      });
    },
    showModal() {
      this.visibleModal = true;
    },
    handleCancel() {
      this.form.resetFields();
      this.visibleModal = false;
    },
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    edit(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = key;
      if (target) {
        target.editable = true;
        this.data = newData;
      }
    },
    save(key) {
      const newData = [...this.data];
      const newCacheData = [...this.cacheData];
      const target = newData.filter((item) => key === item.key)[0];
      const targetCache = newCacheData.filter((item) => key === item.key)[0];
      const updates = { ...target };
      delete updates.key;
      delete updates.id;
      delete updates.editable;
      apiClient
        .patch("/appreciationOption/" + key, { data: updates })
        .then(() => {
          if (target && targetCache) {
            delete target.editable;
            this.data = newData;
            Object.assign(targetCache, target);
            this.cacheData = newCacheData;
          }
          this.editingKey = "";
          this.$message.success(this.$t("success.appreciationOptionMSA"));
        })
        .catch(() => {
          this.$message.error(this.$t("error.modif"));
          this.cancel(key);
        });
    },
    cancel(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = "";
      if (target) {
        Object.assign(
          target,
          this.cacheData.filter((item) => key === item.key)[0]
        );
        delete target.editable;
        this.data = newData;
      }
    },
    supp(key) {
      apiClient
        .delete("/appreciationOption/" + key)
        .then(() => {
          this.$message.success(this.$t("success.appreciationOptionSupp"));
          const dataSource = [...this.data];
          this.data = dataSource.filter((item) => item.key !== key);
        })
        .catch(() => this.$message.error(this.$t("error.supp")));
    },
    changeAppreciationMethod(val) {
      apiClient
        .patch("/buildings/" + this.settings.activeBuildingId, {
          data: { manualAppreciation: this.manualAppreciation },
        })
        .then((res) => {
          if (this.manualAppreciation)
            this.$message.success(this.$t("appreciation.changedToManual"));
          else this.$message.success(this.$t("appreciation.changedToOptions"));
        })
        .catch((e) => {
          console.log(e);
          this.$message.error(this.$t("error.erreur"));
        });
    },
  },
};
</script>
<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
>
