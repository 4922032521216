<template>
  <div>
    <div class="row mb-2">
      <div class="col-md-3">
        <label>{{ $t("bulletin.manageCertType") }} </label>
      </div>

      <div class="col-md-9">
        <a-select
          :default-value="selectedCertType"
          @change="handleCertTypeChange"
          style="width: 100%"
        >
          <a-select-option key="peda" value="peda">
            {{ $t("bulletin.bulletinPedagogique") }}</a-select-option
          >
          <a-select-option key="spec" value="spec">
            {{ $t("bulletin.bulletinVerySpecifique") }}</a-select-option
          >
        </a-select>
      </div>
    </div>
    <a-divider orientation="left"> </a-divider>
    <a-button icon="book" @click="showModal" style="margin-bottom: 20px">
      {{ $t("certification.add") }}
    </a-button>
    <a-popconfirm
      :title="$t('certification.confirmDefault')"
      @confirm="() => setDefaultCertifications()"
    >
      <a-button style="position: absolute; right: 40px">
        {{ $t("certification.default") }}
      </a-button>
    </a-popconfirm>

    <a-modal
      v-model="visibleModal"
      @cancel="handleCancel"
      :title="$t('all.enterInformationBelow')"
      :footer="false"
    >
      <a-form :form="form" @submit="addCertification">
        <a-form-item :label="$t('certification.name')">
          <a-input
            placeholder="e.g: شهادة شكر"
            v-decorator="[
              'name',
              {
                rules: [
                  {
                    required: true,
                    message: $t('requis.nom'),
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <div class="row">
          <div class="col">
            <a-form-item :label="$t('certification.min')">
              <a-input-number
                :min="0"
                :max="20"
                style="width: 80%"
                :placeholder="$t('certification.min')"
                v-decorator="[
                  'min',
                  {
                    rules: [
                      {
                        required: true,
                        message: $t('requis.min'),
                      },
                      {
                        validator: (rule, value, callback) => {
                          if (
                            Number(this.form.getFieldValue('max')) <
                            Number(value)
                          ) {
                            callback($t('certification.minError'));
                          } else {
                            callback();
                          }
                        },
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </div>
          <div class="col">
            <a-form-item :label="$t('certification.max')">
              <a-input-number
                :min="0"
                :max="20"
                style="width: 80%"
                :placeholder="$t('certification.max')"
                v-decorator="[
                  'max',
                  {
                    rules: [
                      {
                        required: true,
                        message: $t('requis.max'),
                      },
                      {
                        validator: (rule, value, callback) => {
                          if (
                            Number(this.form.getFieldValue('min')) >
                            Number(value)
                          ) {
                            callback($t('certification.maxError'));
                          } else {
                            callback();
                          }
                        },
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </div>
        </div>

        <div class="row" v-if="verifyBuildingAccess('certificate')">
          <div class="col">
            <a-form-item :label="$t('choisir.template')">
              <a-radio-group
                v-decorator="[
                  'template',
                  {
                    rules: [
                      {
                        required: true,
                        message: $t('requis.template'),
                      },
                    ],
                  },
                ]"
              >
                <div style="display: flex">
                  <a-radio-button
                    v-for="(certificate, index) of certificates"
                    :key="index"
                    :value="certificate"
                    class="certificate-card"
                  >
                    <img
                      :src="'./certificates/' + certificate + '.png'"
                      style="width: 100%"
                      alt="certificate"
                    />
                  </a-radio-button>
                </div>
              </a-radio-group>
            </a-form-item>
          </div>
        </div>

        <div class="form-actions mt-0">
          <a-button
            type="primary"
            htmlType="submit"
            :loading="loadingClasse"
            :disabled="loadingClasse"
            class="mr-3"
          >
            {{ $t("action.ajouter") }}
          </a-button>
          <a-button
            key="back"
            @click="handleCancel"
            :disabled="loadingClasse"
            >{{ $t("action.annuler") }}</a-button
          >
        </div>
      </a-form>
    </a-modal>

    <a-modal
      v-model="visibleEditModal"
      @cancel="handleCancel"
      :title="$t('all.enterInformationBelow')"
      :footer="false"
    >
      <a-form :form="formEdit" @submit="handelEdit">
        <a-form-item :label="$t('certification.name')">
          <a-input
            placeholder="e.g: شهادة شكر"
            v-decorator="[
              'name',
              {
                rules: [
                  {
                    required: true,
                    message: $t('requis.nom'),
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <div class="row">
          <div class="col">
            <a-form-item :label="$t('certification.min')">
              <a-input-number
                :min="0"
                :max="20"
                style="width: 80%"
                :placeholder="$t('certification.min')"
                v-decorator="[
                  'min',
                  {
                    rules: [
                      {
                        required: true,
                        message: $t('requis.min'),
                      },
                      {
                        validator: (rule, value, callback) => {
                          if (
                            Number(this.form.getFieldValue('max')) <
                            Number(value)
                          ) {
                            callback($t('certification.minError'));
                          } else {
                            callback();
                          }
                        },
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </div>
          <div class="col">
            <a-form-item :label="$t('certification.max')">
              <a-input-number
                :min="0"
                :max="20"
                style="width: 80%"
                :placeholder="$t('certification.max')"
                v-decorator="[
                  'max',
                  {
                    rules: [
                      {
                        required: true,
                        message: $t('requis.max'),
                      },
                      {
                        validator: (rule, value, callback) => {
                          if (
                            Number(this.form.getFieldValue('min')) >
                            Number(value)
                          ) {
                            callback($t('certification.maxError'));
                          } else {
                            callback();
                          }
                        },
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </div>

          <div class="hidden">
            <a-form-item>
              <a-input v-decorator="['id']" />
            </a-form-item>
          </div>
        </div>

        <div class="row" v-if="verifyBuildingAccess('certificate')">
          <div class="col">
            <a-form-item :label="$t('choisir.template')">
              <a-radio-group
                v-decorator="[
                  'template',
                  {
                    rules: [
                      {
                        required: true,
                        message: $t('requis.template'),
                      },
                    ],
                  },
                ]"
              >
                <div style="display: flex">
                  <a-radio-button
                    v-for="(certificate, index) of certificates"
                    :key="index"
                    :value="certificate"
                    class="certificate-card"
                  >
                    <img
                      :src="'./certificates/' + certificate + '.png'"
                      style="width: 100%"
                      alt="certificate"
                    />
                  </a-radio-button>
                </div>
              </a-radio-group>
            </a-form-item>
          </div>
        </div>

        <div class="form-actions mt-0">
          <a-button
            type="primary"
            htmlType="submit"
            :loading="loadingClasse"
            :disabled="loadingClasse"
            class="mr-3"
          >
            {{ $t("action.modifier") }}
          </a-button>
          <a-button
            key="back"
            @click="handleCancel"
            :disabled="loadingClasse"
            >{{ $t("action.annuler") }}</a-button
          >
        </div>
      </a-form>
    </a-modal>
    <div class="card">
      <a-table
        :loading="tableLeading"
        :rowKey="'_id'"
        :columns="columns"
        :data-source="data"
        :pagination="false"
      >
        <template slot="name" slot-scope="text, record">
          <div key="name">
            <a-input
              v-if="record.editable"
              style="margin: -5px 0"
              :value="text"
              @change="(e) => handleChange(e.target.value, record.id, 'name')"
            />
            <template v-else>
              <a-tag>{{ text }}</a-tag>
            </template>
          </div>
        </template>
        <template slot="min" slot-scope="text, record">
          <div key="min">
            <a-input-number
              :min="0"
              :max="20"
              v-if="record.editable"
              style="margin: -5px 0"
              :default-value="text"
              @change="(value) => handleChange(value, record.id, 'min')"
            />
            <template v-else>
              <a-tag color="red">{{ text }}</a-tag></template
            >
          </div>
        </template>
        <template slot="max" slot-scope="text, record">
          <div key="max">
            <a-input-number
              :min="0"
              :max="20"
              v-if="record.editable"
              style="margin: -5px 0"
              :default-value="text"
              @change="(value) => handleChange(value, record.id, 'max')"
            />
            <template v-else>
              <a-tag color="green">{{ text }}</a-tag>
            </template>
          </div>
        </template>
        <span slot="operation" slot-scope="text, record">
          <div class="editable-row-operations">
            <span v-if="record.editable">
              <a-button
                size="small"
                style="margin-left: 5px; margin-right: 5px"
                type="primary"
                @click="() => save(record.id)"
              >
                {{ $t("action.enregistrer") }}
              </a-button>
              <a-button size="small" type="danger">
                <a-popconfirm
                  :title="$t('all.sureToCancelAlert')"
                  @confirm="() => cancel(record.id)"
                >
                  <a>{{ $t("action.annuler") }}</a>
                </a-popconfirm>
              </a-button>
            </span>
            <span v-else>
              <a-button
                type="primary"
                :disabled="editingKey !== ''"
                @click="() => edit(record)"
              >
                <a-icon type="edit" />{{ $t("action.modifier") }}
              </a-button>
            </span>
          </div>
        </span>
        <span slot="operationSupprimer" slot-scope="text, record">
          <div class="editable-row-operations">
            <span>
              <a-button :disabled="editingKey !== ''" type="danger">
                <a-popconfirm
                  :title="$t('requis.supp')"
                  @confirm="() => supp(record.id)"
                >
                  <a>{{ $t("action.supprimer") }}</a>
                </a-popconfirm>
              </a-button>
            </span>
          </div>
        </span>
      </a-table>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import { mapState } from "vuex";

export default {
  name: "certifications",
  beforeMount() {
    this.form = this.$form.createForm(this, { name: "CertificationForm" });
    this.formEdit = this.$form.createForm(this, {
      name: "editCertificationForm",
    });
  },
  async created() {
    this.$gtag.pageview({
      page_path: this.$route.path,
      page_location: window.location.href,
      page_title: document.title + " - Certificat",
    });
    await this.getCetification();
  },
  data() {
    return {
      selectedCertType: "peda",
      columns: [
        {
          title: this.$t("certification.name"),
          dataIndex: "name",
          key: "name",
          scopedSlots: { customRender: "name" },
        },
        {
          title: this.$t("certification.min"),
          dataIndex: "min",
          key: "min",
          scopedSlots: { customRender: "min" },
          sorter: (a, b) => a.min - b.min,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: this.$t("certification.max"),
          dataIndex: "max",
          key: "max",
          scopedSlots: { customRender: "max" },
          sorter: (a, b) => a.max - b.max,
          sortDirections: ["descend", "ascend"],
        },

        {
          title: this.$t("action.modifier"),
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          fixed: "right",
        },
        {
          title: this.$t("action.supprimer"),
          dataIndex: "operationSupprimer",
          scopedSlots: { customRender: "operationSupprimer" },
          fixed: "right",
        },
      ],
      tableLeading: true,
      visibleModal: false,
      visibleEditModal: false,
      form: this.$form.createForm(this),
      data: null,
      cacheData: null,
      loadingClasse: false,
      editingKey: "",
      defaultData: [
        // { id: 1, key: 1, min: 18, max: 20, name: "شهادة امتياز" },
        { id: 2, key: 2, min: 16, max: 17.99, name: "شهادة شكر" },
        { id: 3, key: 3, min: 15, max: 15.99, name: "شهادة شرف" },
        { id: 4, key: 4, min: 14, max: 14.99, name: "شهادة تشجيع" },
        //   { id: 5, key: 5, min: 13, max: 13.99, name: "شهادة رضا" },
      ],
      certificates: [
        "malekEssaghir/charaf",
        "malekEssaghir/choker",
        "malekEssaghir/tachjii",
      ],
    };
  },
  computed: {
    ...mapState(["settings", "user"]),
    schoolDetails() {
      return (
        this.user.building.filter(
          (el) => el.dbName == this.settings.activeBuilding
        )[0] || {}
      );
    },
  },
  methods: {
    verifyBuildingAccess: function (item) {
      let access = this.schoolDetails.access.edManager[item];
      if (access && access == true) return true;
      else return false;
    },
    async handleCertTypeChange(val) {
      this.selectedCertType = val;
      await this.getCetification();
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    async getCetification() {
      this.tableLeading = true;
      await apiClient
        .get("/certification/" + this.selectedCertType)
        .then((res) => {
          let certificationsData = [...res.data];
          if (certificationsData.length == 0)
            this.$message.warning(this.$t("error.aucCert"));
          certificationsData = certificationsData.map((elem) => {
            return {
              ...elem,
              key: elem.id,
            };
          });
          this.data = certificationsData;
          this.cacheData = certificationsData.map((item) => ({ ...item }));
          this.tableLeading = false;
        })
        .catch((e) => {
          this.$message.error(this.$t("error.erreur"));
          this.tableLeading = false;
        });
    },
    addCertification(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.loadingClasse = true;

          apiClient
            .put("/certification/" + this.selectedCertType, { data: values })
            .then((res) => {
              this.$message.success(this.$t("success.certificationAjout"));
              res.data.key = res.data.id;
              this.data.push(res.data);
              this.cacheData = this.data.map((item) => ({ ...item }));
              this.handleCancel();
            })
            .catch((e) =>
              this.$message.error(this.$t("error.impoAjoutCertification"))
            )
            .finally(() => (this.loadingClasse = false));
        }
      });
    },
    showModal() {
      this.visibleModal = true;
    },
    handleCancel() {
      this.form.resetFields();
      this.formEdit.resetFields();
      this.visibleModal = false;
      this.visibleEditModal = false;
    },
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    edit(record) {
      this.formEdit = this.$form.createForm(this);
      this.visibleEditModal = true;
      setTimeout(() => {
        this.formEdit.setFieldsValue({
          name: record.name,
          min: record.min,
          max: record.max,
          id: record.id,
          template: record.template,
        });
      }, 100);
    },
    handelEdit(e) {
      e.preventDefault();
      this.formEdit.validateFieldsAndScroll((err, values) => {
        if (err) return;

        if (!err) this.loadingClasse = true;
        const id = values.id;
        delete values.id;
        apiClient
          .patch("/certification/" + id + "/" + this.selectedCertType, {
            data: values,
          })
          .then(async (res) => {
            await this.getCetification();
            this.$message.success(this.$t("success.certificationMSA"));
            this.loadingClasse = false;
            this.visibleEditModal = false;
          })
          .catch(() => {
            this.$message.error(this.$t("error.modif"));
            this.loadingClasse = false;
          });
      });
    },
    save(key) {
      const newData = [...this.data];
      const newCacheData = [...this.cacheData];
      const target = newData.filter((item) => key === item.key)[0];
      const targetCache = newCacheData.filter((item) => key === item.key)[0];
      const updates = { ...target };
      delete updates.key;
      delete updates.id;
      delete updates.editable;
      apiClient
        .patch("/certification/" + key + "/" + this.selectedCertType, {
          data: updates,
        })
        .then(() => {
          if (target && targetCache) {
            delete target.editable;
            this.data = newData;
            Object.assign(targetCache, target);
            this.cacheData = newCacheData;
          }
          this.editingKey = "";
          this.$message.success(this.$t("success.certificationMSA"));
        })
        .catch(() => {
          this.$message.error(this.$t("error.modif"));
          this.cancel(key);
        });
    },
    cancel(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = "";
      if (target) {
        Object.assign(
          target,
          this.cacheData.filter((item) => key === item.key)[0]
        );
        delete target.editable;
        this.data = newData;
      }
    },
    supp(key) {
      apiClient
        .delete("/certification/" + key + "/" + this.selectedCertType)
        .then(() => {
          this.$message.success(this.$t("success.certificationSupp"));
          const dataSource = [...this.data];
          this.data = dataSource.filter((item) => item.key !== key);
        })
        .catch(() => this.$message.error(this.$t("error.supp")));
    },
    setDefaultCertifications() {
      this.data = this.defaultData.map((item) => ({ ...item }));
      this.cacheData = this.defaultData.map((item) => ({ ...item }));

      const certifParams = this.data.map((c) => ({
        id: c.id,
        name: c.name,
        min: c.min,
        max: c.max,
      }));

      const fieldName =
        this.selectedCertType == "peda" ? "certifParams" : "certifSpecParams";

      apiClient
        .patch("/buildings/" + this.settings.activeBuildingId, {
          data: { [fieldName]: certifParams },
        })
        .then((res) => {
          this.$message.success(this.$t("certification.defaultDone"));
        })
        .catch((e) => {
          console.log(e);
          this.$message.error(this.$t("error.erreur"));
        });
    },
  },
};
</script>
<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hidden {
  display: none;
}

.certificate-card {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
