<template>
  <div>
    <div>
      <div class="row">
        <div class="col-md-11">
          <label>{{ $t("enseignant.listeNiveaux") }} : </label>
          <a-select
            :default-value="defaultLevel[schoolType]"
            @change="handleLevelChange"
            class="pb-2 pt-2 ml-3"
            style="width: 300px"
          >
            <a-select-option
              v-for="(value, name, index) in levelSelectData[schoolType]"
              :key="index"
              :value="name"
            >
              {{ value }}</a-select-option
            >
          </a-select>
        </div>
      </div>
      <template v-if="!loadingData">
        <div v-for="type in types" :key="type.name">
          <div class="card card-body mt-1 mb-2">
            <h5 style="color: blue" class="mb-2">
              {{ $t(type.translation) }}
            </h5>
            <div class="row mb-1">
              <div class="col-4">
                <label class="mr-2"
                  >{{ $t("bulletin.coefTrimester1") }} :
                </label>
                <a-input-number v-model="type.targetData.trimester1" />
              </div>

              <div class="col-4">
                <label class="mr-2"
                  >{{ $t("bulletin.coefTrimester2") }} :
                </label>
                <a-input-number v-model="type.targetData.trimester2" />
              </div>

              <div class="col-4">
                <label class="mr-2"
                  >{{ $t("bulletin.coefTrimester3") }} :
                </label>
                <a-input-number v-model="type.targetData.trimester3" />
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-12 mt-2">
                <label class="mr-2"
                  >{{ $t("bulletin.moyenneGeneral") }} :
                </label>
                <a-tag style="font-size: 18px" color="blue">
                  ( {{ $t("actualite.trimestre1") }} *
                  {{ type.targetData.trimester1 }} +
                  {{ $t("actualite.trimestre2") }} *
                  {{ type.targetData.trimester2 }} +
                  {{ $t("actualite.trimestre3") }} *
                  {{ type.targetData.trimester3 }} ) /
                  {{
                    type.targetData.trimester1 +
                    type.targetData.trimester2 +
                    type.targetData.trimester3
                  }}
                </a-tag>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <a-row>
          <a-col :span="16" :offset="11">
            <a-spin size="large" />
          </a-col>
        </a-row>
      </template>

      <a-divider></a-divider>
      <div class="mt-4">
        <a-button
          :type="checkSaved || loadingData || loadingSave ? '' : 'primary'"
          :loading="loadingSave"
          :disabled="checkSaved || loadingData || loadingSave"
          @click="
            () => {
              saveChange();
            }
          "
          icon="save"
        >
          {{ $t("all.save") }}
        </a-button>
        <a-button
          class="ml-2"
          :loading="loadingSave"
          :disabled="loadingData || loadingSave || checkSaved"
          @click="handleReset"
          icon="undo"
        >
          {{ $t("action.reset") }}
        </a-button>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import { mapState } from "vuex";

export default {
  name: "classrooms",
  beforeMount() {
    this.form = this.$form.createForm(this, { name: "SubjectForm" });
  },
  computed: {
    ...mapState(["settings", "user"]),
    checkSaved() {
      return this.savedTypes === JSON.stringify(this.types);
    },
  },
  async created() {
    this.$gtag.pageview({
      page_path: this.$route.path,
      page_location: window.location.href,
      page_title: document.title + " - Paramètre de coefficient",
    });
    this.schoolType = this.user.building.find(
      (el) => el.dbName == this.settings.activeBuilding
    ).type;

    this.loadingData = true;

    this.schoolDetails = this.user.building.filter(
      (el) => el.dbName == this.settings.activeBuilding
    )[0];

    apiClient
      .post("/bulletinCoef/filter", {
        query: {
          level: this.selectedLevel,
        },
      })
      .then((res) => {
        if (res.data) {
          const pedagogic = res.data.find((el) => el.type == "pedagogic");
          const specific = res.data.find((el) => el.type == "specific");

          const verySpecific = res.data.find((el) => el.type == "verySpecific");

          if (pedagogic) this.types[0].targetData = pedagogic;
          else
            this.types[0].targetData = {
              ...JSON.parse(JSON.stringify(this.defaultTargetData)),
            };

          if (specific) this.types[1].targetData = specific;
          else
            this.types[1].targetData = {
              ...JSON.parse(JSON.stringify(this.defaultTargetData)),
            };

          if (verySpecific) this.types[2].targetData = verySpecific;
          else
            this.types[2].targetData = {
              ...JSON.parse(JSON.stringify(this.defaultTargetData)),
            };

          this.savedTypes = JSON.stringify(this.types);
        }
      })
      .catch((e) => {
        this.$message.error(this.$t("error.erreur"));
      })
      .finally(() => {
        this.loadingData = false;
      });
  },
  data() {
    return {
      schoolType: null,
      schoolDetails: {},
      loadingSave: false,
      form: this.$form.createForm(this),
      data: null,
      savedTypes: "",
      loadingData: false,
      targetKeys: [],
      selectedLevel: "1",
      types: [
        {
          name: "pedagogic",
          translation: "bulletin.bulletinPedagogique",
          targetData: {
            trimester1: 1,
            trimester2: 1.5,
            trimester3: 2,
          },
        },
        {
          name: "specific",
          translation: "bulletin.bulletinSpecifique",
          targetData: {
            trimester1: 1,
            trimester2: 1.5,
            trimester3: 2,
          },
        },
        {
          name: "verySpecific",
          translation: "bulletin.bulletinVerySpecifique",
          targetData: {
            trimester1: 1,
            trimester2: 1.5,
            trimester3: 2,
          },
        },
      ],
      defaultTargetData: {
        trimester1: 1,
        trimester2: 1.5,
        trimester3: 2,
      },
      defaultLevel: {
        ecole: "1",
        college: "7",
        lycee: "10",
        collegeEtLycee: "7",
        primaireEtCollege: "1",
      },
      levelSelectData: {
        jardinEnfant: {
          "-2": this.$t("niveau.niveauPre"),
          "-1": this.$t("niveau.niveau0"),
        },
        ecole: {
          "-2": this.$t("niveau.niveauPre"),
          "-1": this.$t("niveau.niveau0"),
          1: this.$t("niveau.niveau1"),
          2: this.$t("niveau.niveau2"),
          3: this.$t("niveau.niveau3"),
          4: this.$t("niveau.niveau4"),
          5: this.$t("niveau.niveau5"),
          6: this.$t("niveau.niveau6"),
        },
        college: {
          7: this.$t("niveau.niveau7"),
          8: this.$t("niveau.niveau8"),
          9: this.$t("niveau.niveau9"),
        },
        lycee: {
          10: this.$t("niveau.niveau10"),
          11: this.$t("niveau.niveau11"),
          12: this.$t("niveau.niveau12"),
          13: this.$t("niveau.niveau13"),
        },
        collegeEtLycee: {
          7: this.$t("niveau.niveau7"),
          8: this.$t("niveau.niveau8"),
          9: this.$t("niveau.niveau9"),
          10: this.$t("niveau.niveau10"),
          11: this.$t("niveau.niveau11"),
          12: this.$t("niveau.niveau12"),
          13: this.$t("niveau.niveau13"),
        },
        primaireEtCollege: {
          "-2": this.$t("niveau.niveauPre"),
          "-1": this.$t("niveau.niveau0"),
          1: this.$t("niveau.niveau1"),
          2: this.$t("niveau.niveau2"),
          3: this.$t("niveau.niveau3"),
          4: this.$t("niveau.niveau4"),
          5: this.$t("niveau.niveau5"),
          6: this.$t("niveau.niveau6"),
          7: this.$t("niveau.niveau7"),
          8: this.$t("niveau.niveau8"),
          9: this.$t("niveau.niveau9"),
        },
      },
    };
  },
  methods: {
    async saveChange() {
      this.loadingSave = true;

      const savedTypes = JSON.parse(this.savedTypes);

      for (const i in this.types) {
        const target = this.types[i].targetData;

        if (
          JSON.stringify(savedTypes[i].targetData) ==
          JSON.stringify(this.types[i].targetData)
        )
          continue;

        if (target._id) {
          await apiClient
            .patch("/bulletinCoef/" + target._id, {
              data: {
                trimester1: target.trimester1,
                trimester2: target.trimester2,
                trimester3: target.trimester3,
              },
            })
            .then()
            .catch((e) => {
              this.$message.error(this.$t("error.erreur"));
            });
        } else {
          await apiClient
            .put("/bulletinCoef/", {
              data: {
                level: this.selectedLevel,
                type: this.types[i].name,
                trimester1: target.trimester1,
                trimester2: target.trimester2,
                trimester3: target.trimester3,
              },
            })
            .then((res) => {
              if (res.data) {
                this.types[i].targetData = res.data;
              }
            })
            .catch((e) => {
              this.$message.error(this.$t("error.erreur"));
            });
        }
      }

      this.$message.success(this.$t("bulletin.coefBulletinsSaved"));
      this.savedTypes = JSON.stringify(this.types);
      this.loadingSave = false;
    },
    handleLevelChange(val) {
      this.selectedLevel = val;
      this.loadingData = true;
      apiClient
        .post("/bulletinCoef/filter", {
          query: {
            level: this.selectedLevel,
          },
        })
        .then((res) => {
          if (res.data) {
            const pedagogic = res.data.find((el) => el.type == "pedagogic");
            const specific = res.data.find((el) => el.type == "specific");

            const verySpecific = res.data.find(
              (el) => el.type == "verySpecific"
            );

            if (pedagogic) this.types[0].targetData = pedagogic;
            else
              this.types[0].targetData = {
                ...JSON.parse(JSON.stringify(this.defaultTargetData)),
              };

            if (specific) this.types[1].targetData = specific;
            else
              this.types[1].targetData = {
                ...JSON.parse(JSON.stringify(this.defaultTargetData)),
              };

            if (verySpecific) this.types[2].targetData = verySpecific;
            else
              this.types[2].targetData = {
                ...JSON.parse(JSON.stringify(this.defaultTargetData)),
              };

            this.savedTypes = JSON.stringify(this.types);
          }
        })
        .catch((e) => {
          this.$message.error(this.$t("error.erreur"));
        })
        .finally(() => {
          this.loadingData = false;
        });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    renderItem(item) {
      const customLabel = <span class="custom-item">{item.name}</span>;
      return {
        label: customLabel, // for displayed item
        value: item.name, // for title and filter matching
      };
    },
    handleChangeTransfer(targetKeys, direction, moveKeys) {
      this.targetKeys = targetKeys;
    },
    handleAddChangeTransfer(targetKeys, direction, moveKeys) {
      this.targetAddKeys = targetKeys;
      console.log(this.targetAddKeys);
    },
    showModal() {
      this.visibleModal = true;
    },
    showAddModal() {
      this.visibleAddModal = true;
    },
    handleReset() {
      this.types = JSON.parse(this.savedTypes);
    },
  },
};
</script>
<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.arabicStyle {
  direction: rtl;
  font-size: 20px;
}

.card >>> .ant-table-tbody tr {
  cursor: pointer !important;
}
</style>
